(function ($) {

    if (document.querySelectorAll('[data-component="carousel"]').length > 0) {
            
        if ($('html').attr('dir') === 'rtl') {
            document.addEventListener('DOMContentLoaded', function () {
                $('#slickServices').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    variableWidth: true,
                    lazyLoad: 'progressive',
                    nextArrow: $('#slickServicesNext'),
                    prevArrow: $('#slickServicesPrev'),
                    rtl: true,
                    responsive: [{
                        breakpoint: 1194,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false,
                        }
                    }, {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            variableWidth: false,
                        }
                    }]
                });
        
                $('#slickBanner').slick({
                    arrows: true,
                    dots: true,
                    infinite: false,
                    rtl: true,
                    nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
                    prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',    
                });
        
                $('#slickGallery').slick({
                    arrows: true,
                    dots: false,
                    infinite: false,
                    rtl: true,
                    nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
                    prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',    
                });
    
                $('#slickValue').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    nextArrow: $('#slickValueNext'),
                    prevArrow: $('#slickValuePrev'),
                    variableWidth: true,
                    rtl: true,
                    responsive: [{
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false,
                        }
                    }, {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            variableWidth: false,
                        }
                    }]
                }).on('setPosition', function (e, slick) {
                    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
                });    
            })
        } else {
            document.addEventListener('DOMContentLoaded', function () {
                $('#slickServices').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    variableWidth: true,
                    lazyLoad: 'progressive',
                    nextArrow: $('#slickServicesNext'),
                    prevArrow: $('#slickServicesPrev'),
                    responsive: [{
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false,
                        }
                    }, {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            variableWidth: false,
                        }
                    }]
                });
        
                $('#slickBanner').slick({
                    arrows: true,
                    dots: true,
                    infinite: false,
                    nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
                    prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',    
                });
        
                $('#slickGallery').slick({
                    arrows: true,
                    dots: false,
                    infinite: false,
                    nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
                    prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',    
                });
    
                $('#slickValue').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    lazyLoad: 'progressive',
                    nextArrow: $('#slickValueNext'),
                    prevArrow: $('#slickValuePrev'),
                    variableWidth: true,
                    responsive: [{
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false,
                        }
                    }, {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            variableWidth: false,
                        }
                    }]
                }).on('setPosition', function (e, slick) {
                    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
                });
            })
        }
    
    }

})(jQuery);