if (document.querySelectorAll('[data-component="alert"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const element = document.getElementById('registrationForm');

        // Add an event listener to the form submission
        element.addEventListener('submit', function(event) {
            // Prevent the default form submission
            event.preventDefault();
        
            // Show the success modal
            let myModal = new bootstrap.Modal(document.getElementById('successModal'));
            myModal.show();
        });
  
    })
}